import {Link} from "react-router-dom";
import './index.scss';
import React from "react";
import BC from '../../assets/images/BC.png'
import BCR from '../../assets/images/BCR.png'
import sushi from '../../assets/images/sushi.png'
import SCB from '../../assets/images/sweetCB.png'
import CB from '../../assets/images/CB.png'
import { ImageSlider } from "../ImageSlider/ImageSlider"
import Loader from "react-loaders";

const Images = [
    { url: BC, alt: "Image One" },
    { url: BCR, alt: "Image Two" },
    { url: sushi, alt: "Image Three" },
    { url: SCB, alt: "Image Four" },
    { url: CB, alt: "Image Five" },
]
const Home = () => {

    return (
        <div>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span>King's</span>
                        <br/>
                        <span>Vegetarian Food</span>
                    </h1>
                    <h2>Kelowna's Vegetarian/Vegan Food Truck</h2>
                    <Link to="/contact" className='flat-button'>ORDER FOOD</Link>
                </div>
                <div className='Logo-container'>
                    <ImageSlider images={Images}/>
                </div>
            </div>
            <Loader type='ball-pulse' active/>
        </div>

    );
};

export default Home;