import React from 'react';

import './index.css';
import {faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";

function Footer () {

    return (
        <div className="footer">
            <div className="footer-info">
                <div className="footer-info-left">
                    <div className="footer-info-contact">
                        Contact US
                        <br/>
                        <a href="https://maps.app.goo.gl/AFPT4Pz1vFWdoiot5" target='_blank' rel="noreferrer">
                        <FontAwesomeIcon icon={faLocationDot} color='#111'/>
                        &nbsp;Kelowna Farmers Crafters Market and Vernon Farmer’s Market
                        </a>
                        <br/>
                        <a href="tel:250-860-7268">
                            <FontAwesomeIcon icon={faPhone} color='#111'/>
                            &nbsp;(250) 860-7268
                        </a>
                        <br/>
                        <a href="mailto:contact@kingsvegetarianfood.com">
                            <FontAwesomeIcon icon={faEnvelope} color='#111'/>
                            &nbsp;contact@kingsvegetarianfood.com
                        </a>
                    </div>
                </div>
                <div className="footer-info-center">
                    <div className="footer-info__terms">
                        © King's Vegetarian Food Kelowna 2023
                        <br/>
                        Web Development by <a href="https://kuanyichiang.com/" target='_blank' rel="noreferrer">Kevin Chiang</a>
                    </div>
                </div>
                <div className="footer-info-right">
                    <div className="footer-info-social">
                        <a href="https://www.facebook.com/kingsvegetarian" target='_blank' rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} color='#111'/>
                        </a>
                        &nbsp;&nbsp;
                        <a href="https://www.instagram.com/kingsvegetarianfoodkelowna/" target='_blank' rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} color='#111'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer;