import './index.scss';

import React from "react";
import Loader from "react-loaders";
import King from "../../assets/images/att.sXfP7r9gsGWGZ11FRW4otu0DSghiheGAEusUk2cwzqU-ezgif.com-resize.jpg";


const About = () => {

    return (
        <div>
            <div className="container about-page">
                <div className="text-zone">
                    <h1>About</h1>
                    <p> King's Vegetarian Food is a small family-owned business. We sell our food at the Kelowna
                        Farmers' &
                        Crafters' Market or other Fair Events at various locations. We take photos of our business at
                        the
                        locations we sell and we like to share them with you by posting them on the website. We provides
                        you delicious, nutritious, healthy, vegetarian vegan food to take away, or delivery. We sell at
                        the
                        Kelowna Farmers' & Crafters' Market or other Fair Events at various locations. Please refer to
                        our
                        selling locations listed below. We also take orders from home and allow pick-up. Please contact
                        Janice at (250) 860-7268 or (250) 864-7268, or email us at contact@kingsvegetarianfood.com</p>
                </div>
                <img src={King} alt={King}/>
            </div>
            <Loader type='ball-pulse'/>
        </div>
    );
};

export default About;
