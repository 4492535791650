import './index.scss'
import React from 'react';
import {Link, NavLink} from "react-router-dom";
import Logo from '../../assets/images/King.png'

const Sidebar = () => {
    return (
        <div className='nav-bar'>
            <Link className='logo' to="/">
                <img src={Logo} alt='logo'/>
            </Link>
            <nav>
                <NavLink exact={true} activeClassName='active' to='/'>
                    HOME
                </NavLink>
                <NavLink exact={true} activeClassName='active' className="Menu-link" to='/Menu'>
                    MENU
                </NavLink>
                <NavLink exact={true} activeClassName='active' className="about-link" to='/about'>
                    ABOUT
                </NavLink>
                <NavLink exact={true} activeClassName='active' className="Location-link" to='/Location'>
                    LOCATION
                </NavLink>
                <NavLink exact={true} activeClassName='active' className="contact-link" to='/Contact'>
                    CONTACT
                </NavLink>
            </nav>
        </div>
    );
};

export default Sidebar;
