import './index.scss';
import React, {useRef} from "react";
import Loader from "react-loaders";
import emailjs from '@emailjs/browser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";

const Contact = () => {
    const formRef = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_h0te01n', 'template_00uchul', formRef.current, 'WvHQuPOkIIFGM6hWI')
            .then(
                (response) => {
                    console.log('Email sent successfully:', response);
                    alert('Email successfully sent!');
                },
                (error) => {
                    console.error('Failed to send the Email:', error);
                    alert('Failed to send the Email, please try again');
                }
            );

    }

    return (
        <div>
            <div className="container contact-page">
                <div className="text-zone">
                    <h1>Contact</h1>
                    <p>We take orders from home and allow pick-up. Please contact Janice at (250) 860-7268 or
                        (250) 864-7268, Facebook, Instagram or email us at contact@kingsvegetarianfood.com</p>
                    <div className="social">
                        <a href="https://www.facebook.com/kingsvegetarian" target='_blank' rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} color='#111'/>
                        </a>
                        &nbsp;&nbsp;
                        <a href="https://www.instagram.com/kingsvegetarianfoodkelowna/" target='_blank'
                           rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} color='#111'/>
                        </a>
                    </div>
                </div>
                <div className="contact-form">
                    <form ref={formRef} onSubmit={sendEmail}>
                        <ul>
                            <li className="half">
                                <input placeholder="Name" type="text" name="name" required/>
                            </li>
                            <li className="half">
                                <input placeholder="Email" type="email" name="email" required/>
                            </li>
                            <li>
                            <input placeholder="Subject" type="text" name="subject" required/>
                            </li>
                            <li>
                                <textarea placeholder="Message" name="message" required></textarea>
                            </li>
                            <li>
                                <input type="submit" className="flat-button" value="SEND" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <Loader type='ball-pulse' active />
        </div>
    );
};

export default Contact;
