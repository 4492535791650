import './index.scss';
import React from "react";
import Loader from "react-loaders";


const Location = () => {
    return (
        <div>
            <div className="container Location-page">
                <div className="text-zone">
                    <h1>Location</h1>
                    <p>Kelowna Farmers and Crafters Market <br/>
                        Every Wed & Sat From 8 AM to 1 PM</p>
                    <p>Vernon Farmer’s Market <br/>
                        Every Monday and Thursday 8am to 1pm</p>
                </div>
                <div className='map-zone'>
                    <div className="iframe-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10283.901290286245!2d-119.461466!3d49.88049!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537d8d004dc79039%3A0x1e7dc3e575a35655!2sKing&#39;s%20Vegetarian%20Food!5e0!3m2!1sen!2sca!4v1712953433333!5m2!1sen!2sca"
                            width="400" height="300" allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade" title="Kelowna Map"
                            className="kelowna-map"></iframe>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2549.885098010834!2d-119.277372!3d50.275404!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537dd894f98551b9%3A0x9815d5eca88952b2!2sKal%20Tire%20Place!5e0!3m2!1sen!2sca!4v1712953542231!5m2!1sen!2sca"
                            width="400" height="300" allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade" title="Vernon Map"
                            className="vernon-map"></iframe>
                    </div>
                </div>
            </div>
            <Loader type='ball-pulse' active/>
        </div>
    );
};

export default Location;
