import './index.scss'
import React from 'react';
import Sidebar from "../Sidebar";
import {Outlet} from "react-router";
import Footer from "../Footer";

const Layout = () => {
    return (
        <div className="App">
            <Sidebar/>
            <div className="page">
                <Outlet/>
            </div>
            <div className="footer-info__terms">
                © King's Vegetarian Food Kelowna 2023
                <br/>
                Web Development by <a href="https://kuanyichiang.com/" target='_blank' rel="noreferrer">Kevin Chiang</a>
            </div>
            <Footer/>
        </div>
    );
};

export default Layout;