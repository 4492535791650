import './index.scss';

import React, {useEffect, useState} from "react";
import Loader from "react-loaders";
import BC from '../../assets/images/BC.png'
import BD from '../../assets/images/BD.png'
import CB from '../../assets/images/CB.png'
import HCB from '../../assets/images/HCB.png'
import SR from '../../assets/images/SR.png'
import SPR from '../../assets/images/SPR.png'
import sushi from '../../assets/images/sushi.png'
import SCB from '../../assets/images/sweetCB.png'
import VN from '../../assets/images/VN.png'
import BBT from '../../assets/images/bbt.jpg'
import BUN from '../../assets/images/BUN.jpg'

const Menu = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            setTimeout(() => {
                const data = [{
                    id: 1,
                    category: 'General Food',
                    name: 'Basil Cake',
                    price: 4,
                    image: BC,
                    Description: (
                        <div>
                            <b>Ingredients: </b> Flour, Chinese basil, Chinese mushroom, and sesame
                        </div>
                    ),
                },
                    {
                        id: 2,
                        category: 'General Food',
                        name: 'Steamed Buns',
                        price: 3.50,
                        image: BUN,
                        Flavor: (
                            <div>
                                <b>Flavor: </b> Red Bean, Curry, Sweet & Sour Veggie
                            </div>
                        ),
                        Description: (
                            <div>
                                <b>Ingredients: </b> <br/>
                                Red Bean: Flour, adzuki bean, and sugar. <br/>
                                Curry: Flour, cabbage, Chinese mushroom, carrot, soy protein, <br/>
                                mushroom powder, sugar, curry powder, and soy sauce. <br/>
                                Sweet & Sour Veggie: Flour and sweet & sour veggie.
                            </div>
                        ),
                    },
                    {
                        id: 3,
                        category: 'General Food',
                        name: 'Boiled Dumpling',
                        price: 7.50,
                        image: BD,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Flour, cabbage, carrot, Chinese mushroom,<br/>
                                mung beans noodle, basil, soy protein, wheat protein, <br/>
                                soy sauce, salt, sesame oil, and white pepper
                            </div>
                        ),
                    },
                    {
                        id: 4,
                        category: 'General Food',
                        name: 'Crispy Beans (curry sauce or sweet spicy sauce)',
                        price: 7.50,
                        image: CB,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy bean protein
                            </div>
                        ),
                    },
                    {
                        id: 5,
                        category: 'General Food',
                        name: 'Honey Crispy Beans',
                        price: 7,
                        image: HCB,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy milk (bean curd skin)
                            </div>
                        ),
                    },
                    {
                        id: 6,
                        category: 'General Food',
                        name: 'Salad Roll',
                        price: 6.50,
                        image: SR,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Rice wrap, lettuce, rice noodle, soy ham (soy protein),<br/>
                                mint, cilantro, basil, cucumber, and pickled (carrot, daikon)
                            </div>
                        ),
                    },
                    {
                        id: 7,
                        category: 'General Food',
                        name: 'Spring Roll',
                        price: 6.50,
                        image: SPR,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Flour wrap, cabbage, carrot, Chinese mushroom, and soy ham
                            </div>
                        ),
                    },
                    {
                        id: 8,
                        category: 'General Food',
                        name: 'Sushi(California Roll)',
                        price: 7.50,
                        image: sushi,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Seaweed, rice, cucumber, <br/>
                                pickled (carrot, daikon), avocado, red pepper, <br/>
                                soy protein fiber, and soy ham (soy protein)
                            </div>
                        ),
                    },
                    {
                        id: 9,
                        category: 'General Food',
                        name: 'Sweet & Sour Veggie',
                        price: 7.50,
                        image: SCB,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy bean protein
                            </div>
                        ),
                    },
                    {
                        id: 10,
                        category: 'General Food',
                        name: 'Veggie Nuggets',
                        price: 7.50,
                        image: VN,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy bean protein
                            </div>
                        ),
                    },
                    {
                        id: 11,
                        category: 'Drink',
                        name: 'Bubble Tea',
                        price: '6 (Small), $6.50(Large)',
                        image: BBT,
                        Flavor: (
                            <div>
                                <b>Flavor:</b> Milk Tea, Green Tea, <br/>
                                Black Tea, Taro, Strawberry, <br/>
                                Peach, Mango, Watermelon, <br/>
                                Passion Fruit, Lychee, <br/>
                                Honeydew Melon, Blueberry,<br/>
                                Coconut
                            </div>
                        ),
                    },
                    {
                        id: 12,
                        category: 'Drink',
                        name: 'Lemonade',
                        price: '4.50 (Small), $5(Large)',
                        image: BBT,
                        Flavor: (
                            <div>
                                <b>Flavor:</b> Strawberry, Mango, <br/>
                                Passion Fruit, Blueberry
                            </div>
                        ),
                    },
                    {
                        id: 13,
                        category: 'Frozen Food',
                        name: 'Basil Cake',
                        price: '18, $30',
                        image: BC,
                        quantity: (
                            <div>
                                <b>Quantity: </b> 5,10
                            </div>
                        ),
                        Description: (
                            <div>
                                <b>Ingredients: </b> Flour, Chinese basil, Chinese mushroom, and sesame
                            </div>
                        ),
                    },
                    {
                        id: 14,
                        category: 'Frozen Food',
                        name: 'Steamed Buns',
                        price: 24,
                        Flavor: (
                            <div>
                                <b>Flavor: </b> Red Bean, Curry, Sweet & Sour Veggie
                            </div>
                        ),
                        image: BUN,
                        quantity:(
                            <div>
                                <b>Quantity: </b> 8
                            </div>
                        ),
                        Description: (
                            <div>
                                <b>Ingredients: </b> <br/>
                                Red Bean: Flour, adzuki bean, and sugar. <br/>
                                Curry: Flour, cabbage, Chinese mushroom, carrot, soy protein, <br/>
                                mushroom powder, sugar, curry powder, and soy sauce. <br/>
                                Sweet & Sour Veggie: Flour and sweet & sour veggie.
                            </div>
                        ),
                    },
                    {
                        id: 15,
                        category: 'Frozen Food',
                        name: 'Boiled Dumpling',
                        price: '7.50, $14',
                        image: BD,
                        quantity:(
                            <div>
                                <b>Quantity: </b> 10,20
                            </div>
                        ),
                        Description: (
                            <div>
                                <b>Ingredients: </b> Flour, cabbage, carrot, Chinese mushroom,<br/>
                                mung beans noodle, basil, soy protein, wheat protein, <br/>
                                soy sauce, salt, sesame oil, and white pepper
                            </div>
                        ),
                    },
                    {
                        id: 16,
                        category: 'Frozen Food',
                        name: 'Crispy Beans',
                        price: 7.50,
                        image: CB,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy bean protein
                            </div>
                        ),
                    },
                    {
                        id: 17,
                        category: 'Frozen Food',
                        name: 'Honey Crispy Beans',
                        price: 7,
                        image: HCB,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy milk (bean curd skin)
                            </div>
                        ),
                    },
                    {
                        id: 18,
                        category: 'Frozen Food',
                        name: 'Spring Roll',
                        price: 12,
                        image: SPR,
                        quantity:(
                            <div>
                                <b>Quantity: </b> 10
                            </div>
                        ),
                        Description: (
                            <div>
                                <b>Ingredients: </b> Flour wrap, cabbage, carrot, Chinese mushroom, and soy ham
                            </div>
                        ),
                    },
                    {
                        id: 19,
                        category: 'Frozen Food',
                        name: 'Sweet & Sour Veggie',
                        price: 7.50,
                        image: SCB,
                        Description: (
                            <div>
                                <b>Ingredients: </b> Soy bean protein
                            </div>
                        ),
                    },
                    {
                        id: 20,
                        category: 'Frozen Food',
                        name: 'Veggie Nuggets',
                        price: 7.50,
                        image: VN,
                        Description: (
                                <div>
                                    <b>Ingredients: </b> Soy bean protein
                                </div>
                            ),
                    },
                ];
                setMenuItems(data);
                setIsLoading(false);
            }, 2000);
        };

        fetchData();
    }, []);
    const renderMenuItems = category => {
        return menuItems
            .filter(item => item.category === category)
            .map(item => (
                <div>
                    <div className="menu-category" key={item.id}>
                        <img src={item.image} alt={item.name}/>
                        <div className="item-details">
                            <div><b>{item.name}</b> ${item.price}</div>
                            <div>{item.quantity}</div>
                            <div>{item.Flavor}</div>
                            <div>{item.Description}</div>
                        </div>
                    </div>
                </div>

            ));
    };

    return (
        <div>
            {isLoading ? (
                <Loader type='ball-pulse' active/>
            ) : (
                <div className="menu">
                    <div className="general-Food">
                        <h1>General Food</h1>
                        <div className="menu-category">{renderMenuItems('General Food')}</div>
                    </div>
                    <div className="frozen-Food">
                        <h1>Frozen Food</h1>
                        <div className="menu-category">{renderMenuItems('Frozen Food')}</div>
                    </div>
                    <div className={"drinks"}>
                        <h1>Drinks</h1>
                        <div className="menu-category">{renderMenuItems('Drink')}</div>
                    </div>
                </div>
            )}
        </div>


    );
};


export default Menu;
