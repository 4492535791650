import './App.scss';
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import Menu from "./components/Menu";
import Location from "./components/Location";
import Contact from "./components/Contact";
import Admin from "./components/Admin";

function App() {
  return (
      <>
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/Menu" element={<Menu/>}/>
                <Route path="/Location" element={<Location/>}/>
                <Route path="/Contact" element={<Contact/>}/>
                <Route path="/Admin" element={<Admin/>}/>
            </Route>
        </Routes>
      </>
  )
}

export default App;
